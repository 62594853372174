import { AuthenticationService } from './authentication.service';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedInAuthGuardService implements CanActivate {

  constructor(public authenticationService: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const authenticated = this.authenticationService.isLoggedIn();

    if (authenticated) {
      this.router.navigate(['main']);
      return false;
    }
    return  true;
  }
}

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(private loadingController: LoadingController, private translate: TranslateService) { }

  async present(): Promise<void> {
    this.isLoading = true;
    return await this.loadingController.create({
      message: await this.translate.get('LOADING').toPromise()
    }).then(loader => {
      loader.present().then(() => {
        if (!this.isLoading) {
          loader.dismiss()
        }
      });
    });
  }

  async dismiss(): Promise<boolean> {
    this.isLoading = false;
    return await this.loadingController.dismiss();
  }
}
import { Attendance, Operativa } from './../interfaces/interfaces';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import { User } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private user: User;
  private operativa: Operativa;

  constructor(private apollo: Apollo) { }

  set(data: User) {
    sessionStorage.setItem('user', JSON.stringify(data));
  }

  get() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    return this.user;
  }

  getOperativas() {
    return this.user.operativas ? this.user.operativas : [];
  }

  setOperativa(data: Operativa) {
    sessionStorage.setItem('operativa', JSON.stringify(data));
  }

  getOperativa() {
    this.operativa = JSON.parse(sessionStorage.getItem('operativa'));
    return this.operativa;
  }

  getQrCode() {
    return this.user.operativas.length > 0 && this.operativa ? this.operativa.qrcodeString : null;
  }

  destroy() {
    sessionStorage.removeItem('operativa');
    sessionStorage.removeItem('user');
    this.user = null;
    this.operativa = null;
  }

  userAttendance(code: string) {
    return this.apollo.mutate<Attendance>({
      fetchPolicy: 'no-cache',
      variables: { meta: null, qrcodeString: code },
      mutation: gql`
        mutation createUserAttendance($qrcodeString: String!){
          createUserAttendance(input: {qrcodeString: $qrcodeString}) {
            userAttendance{
              state
            }
            message
            errors
          }
        }
        `,
    });
  }

  getRandomOperativas() {
    if (this.user.operativas) {
      return this.user.operativas.filter(operativa => operativa.random).length > 0;
    }
    return false;
  }

  getPDRUser() {
    return this.user.operations.filter(this.operationsAllowed).length > 0;
  }

  operationsAllowed(operation) {
    const operations: string[] = ['10'];
    return operations.indexOf(operation.id) !== -1;
  }

  getNextState(userId: string, operativa: number) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      variables: { id: userId, operativaId: operativa},
      query: gql`
        query user($id: String!, $operativaId: Int) {
          user(id: $id, operativaId: $operativaId) {
            email,
            nextAttendanceState
          }
        }
      `,
    });
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ReportFilterType } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private apollo: Apollo) { }

  getReportAvailableOperations() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          reportAvailableOperations{
            id
            title
          }
        }
      `
    }).valueChanges;
  }

  getReportAvailableOperativas() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          reportAvailableOperativas{
            id
            title
          }
        }
      `
    }).valueChanges;
  }

  getReportVehicles(actualPage: number, reportFilter: ReportFilterType) {
    const reportFilterParams = `
      ${ reportFilter.vinOrLicensePlate ? ', vinOrLicensePlate: "' + reportFilter.vinOrLicensePlate + '"' : ''}
      ${ reportFilter.operativaId ? ', operativaId:' + +reportFilter.operativaId : ''}
      ${ reportFilter.operationId ? ', operationId:' + +reportFilter.operationId : ''}
      ${ reportFilter.fromDate ? ', fromDate:"' + reportFilter.fromDate + '"' : ''}
      ${ reportFilter.toDate ? ', toDate:"' + reportFilter.toDate + '"' : ''}`.substring(2);

    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
      query {
        reportVehicles(page:${actualPage}, reportFilter: {${reportFilterParams}}){
          vehicles{
            vinOrLicensePlate
            modelAndBrand
            reportOperationOutcomes{
              operation{
                title
              }
              state
              assemblyPrice
              disassemblyPrice
              pdrPrice
              operatorFullname
              createdAtToS
            }
            operativa {
              title
            }
          }
          actualPage
          totalPages
        }
      }
      `
    }).valueChanges;
  }

  sendExcel(kind: string, reportFilter: ReportFilterType) {
    return this.apollo.mutate({
      fetchPolicy: 'no-cache',
      variables: {
        kind,
        reportFilter
      },
      mutation: gql`
        mutation sendReportToUser($kind: String!, $reportFilter: ReportFilterType!){
          sendReportToUser(input: {kind: $kind, reportFilter: $reportFilter}) {
            success
          }
        }
      `,
    });
  }

  getOperativasWithCarTransporters(isLoad: boolean) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          operativasWithCarTransporters(load: ${isLoad}) {
            id
            title
            carTransportersLoaded{
              id
              fullDescription
              vehiclesToUnload{
                id
                licensePlate
                vin
                vinOrLicensePlate
              }
            }
            carTransportersUnloaded{
              id
              fullDescription
            }
          }
        }
      `
    });
  }

  getOperativasWithLogisticPartners() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
          query {
            operativasWithLogisticPartners {
              id
              title
              logisticPartners{
                id
                fullName
                logisticPartnerJourneys{
                  id
                  departure
                  destination
                  roundtrip
                }
              }
            }
          }`
        }).valueChanges;
  }

  createCarTransporter(variables: {licensePlate: string, logisticPartnerJourneyId: number, operativaId: number, roundtrip: boolean}) {
    return this.apollo.mutate({
      fetchPolicy: 'no-cache',
      variables,
      mutation: gql`
        mutation createCarTransporter($licensePlate: String!, $logisticPartnerJourneyId: Int!, $operativaId: Int!,  $roundtrip: Boolean!){
          createCarTransporter(input: {licensePlate: $licensePlate, logisticPartnerJourneyId: $logisticPartnerJourneyId, operativaId: $operativaId, roundtrip: $roundtrip }) {
            carTransporter {
              id
            }
            success
            errors
          }
        }
      `
    });
  }

  getOperativasWithUserAttendance() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          operativasWithUserAttendance{
            id
            title
            branchName
            dayWorkerTotal{
              firstAndLastDay
              days
              hours
              pricesPerDay
              totalToInvoice
            }
            dayWorkerDays{
              day
              halfDay
              dayPrice
            }
          }
        }
      `
    }).valueChanges;
  }
}

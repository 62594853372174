import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-vehicle',
  templateUrl: './select-vehicle.page.html',
  styleUrls: ['./select-vehicle.page.scss'],
})
export class SelectVehiclePage implements OnInit {

  idVehicle: string;
  vehiclesList: any;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.idVehicle = null;
  }

  selectedValue(event) {
    this.idVehicle = event.detail.value;
    if(this.idVehicle) {
      const vehicle = this.vehiclesList.filter(vehicle => vehicle.id === +this.idVehicle)[0];
      const value = vehicle.vin ? vehicle.vin : vehicle.license_plate ? vehicle.license_plate : '';
      this.alertConfirm(this.translate.instant('MESSAGES.vehiclesSelected', {name: value, operativa: vehicle.operativa || ''}), this.idVehicle);
    }
  }

  sendID(id) {
    this.closeModal();
    this.router.navigate(['/main/operation', id]);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async alertConfirm(msg: string, id: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant('ATTENTION'),
      subHeader: '',
      message: msg,
      buttons: [{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
      },
      {
        text: this.translate.instant('CONFIRM'),
        handler: () => {
          this.sendID(id);
        }
      }]
    });

    await alert.present();
  }

}

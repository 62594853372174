import { Vehicle } from './../interfaces/interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root'
})
export class ProcessingLinesService {
  vehicle: any;

  constructor(private apollo: Apollo) { }

  active(operativaId: string) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          operativa(id:"${operativaId}") {
            currentProcessingLine
          }
        }
      `
    });
  }

  getRandomLine(operativaId: string) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          operativa(id:"${operativaId}") {
            randomProcessingVehicle{
              id
              vinOrLicensePlate
              processingRow
              model
            }
          }
        }
      `
    });
  }

  set(vehicle: any) {
    this.vehicle = vehicle;
  }

  get() {
    return this.vehicle ? this.vehicle : null;
  }

  delete() {
    this.vehicle = null;
  }
}

import { OperationOutcomeInputType } from './../interfaces/interfaces';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private apollo: Apollo) { }

  set(operationOutcomeInput: OperationOutcomeInputType) {
    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      variables: {
        operationOutcomeInput
      },
      mutation: gql`
        mutation createOperationOutcome( $operationOutcomeInput: OperationOutcomeInputType! ){
          createOperationOutcome(input: { operationOutcomeInput: $operationOutcomeInput }) {
            operationOutcome {
              id
            }
            success
            errors
          }
        }
      `,
    });
  }

  getInternalLogisticsDestinations(operationId: string) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          internalLogisticsDestinations(operativaId: ${operationId})
        }
        `
    });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  domain: 'https://levertouch.zeroapps.com',
  graphQLUrl: 'https://levertouch.zeroapps.com/graphql',
  restUrl: 'https://levertouch.zeroapps.com/api/v1',
  language: 'it'
};

// export const environment = {
//   production: false,
//   domain: 'http://localhost:3000',
//   graphQLUrl: 'http://localhost:3000/graphql',
//   restUrl: 'http://localhost:3000/api/v1',
//   language: 'it'
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

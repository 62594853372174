export enum OperationStatus {
    in_progress = 'in_progress',
    closed = 'closed',
    end = 'end',
    start = 'start',
    load = 'load',
    unload = 'unload'
}

export enum ChecklistItemAnswerStatus {
    ok = 'ok',
    ko = 'ko'
}

export enum PaintedStatus {
    ok_painted = 'OK_PAINTED',
    to_be_polished = 'TO_BE_POLISHED',
    to_repaint = 'TO_REPAINT'
}

export enum ToPaintStatus {
    painted = 'PAINTED',
    not_painted = 'NOT_PAINTED'
}

export enum SparePartStatus {
    mounted = 'MOUNTED',
    replacementNotNeeded = 'REPLACEMENT_NOT_NEEDED',
    defectivePart = 'DEFECTIVE_PART'
}

import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VinService {

  private id: BehaviorSubject<string> = new BehaviorSubject(
    sessionStorage.getItem('id') ? sessionStorage.getItem('id') : ''
  );

  id$ = this.id.asObservable();
  private badReadingData: any;
  ocr: string;


  constructor(private http: HttpClient) { }

  uploadImage(image: File, isLicenseScanner = false) {
    const formData = new FormData();
    formData.append('image', image);
    const analyzeUrl = isLicenseScanner ? 'license_plate_analyze' : 'vin_analyze';
    const link = `${environment.restUrl}/${analyzeUrl}`;
    const options: any = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      })
    };
    return this.http.post(link, formData, options);
  }

  genericText(text: string) {
    const formData = new FormData();
    formData.append('vin_or_license_plate', text.replace(/\s+/g, ''));
    const analyzeUrl = 'find_by_free_text';
    const link = `${environment.restUrl}/${analyzeUrl}`;
    const options: any = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + sessionStorage.getItem('token')
      })
    };
    return this.http.post(link, formData, options);
  }

  setBadReadedData(code: string, img: string) {
    this.badReadingData = {
      image: img,
      ocr: code
    };

    this.setOCR(code);
  }

  setOCR(data) {
    this.ocr = data ? data : null;
  }

  getOCR() {
    return this.ocr ? this.ocr : null;
  }

  delOCR() {
    this.ocr = null;
  }

  getBadReadedData() {
    return this.badReadingData;
  }

  delBadReadedData() {
    this.badReadingData = null;
  }

  set(data) {
    sessionStorage.setItem('id', data);
    this.id.next(data);
  }

  destroy() {
    this.delOCR();
    this.set('');
    sessionStorage.removeItem('id');
  }
}

import { TranslateService } from '@ngx-translate/core';
import { VinService } from './../../services/vin.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SelectVehiclePage } from '../select-vehicle/select-vehicle.page';

@Component({
  selector: 'app-vin-form',
  templateUrl: './vin-form.page.html',
  styleUrls: ['./vin-form.page.scss'],
})
export class VinFormPage implements OnInit {

  vin: string;
  picture: string;
  vinForm: FormGroup;
  ocr: string;
  @Input() isIndependent: boolean;
  isLicense: boolean;
  currentModal = null;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private vinService: VinService,
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
  ) {
    this.vin = null
  }

  ngOnInit() {
    this.vinForm = this.formBuilder.group({
      vinCode: ['', Validators.pattern('([A-Z0-9-]{7,17})')]
    });

    const data = this.vinService.getBadReadedData();
    if (data) {
      this.picture = data.image;
      this.vin = data.ocr;
    }

    this.vinForm.setValue({
      vinCode: this.vin
    });
  }

  ionViewDidLeave() {
    this.vinService.delBadReadedData();
  }

  sendVin() {
    const { vinCode } = this.vinForm.value;

    if (this.isIndependent) {
      this.modalController.dismiss(vinCode);
    } else {
      this.vinService.genericText(vinCode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.checkMessage(res);
        },
        (err) => {
          this.alertMessage('Server error');
      });
      //this.router.navigate(['/main/operation', vinCode]);
    }
  }

  async alertMessage( msg: any): Promise<void> {
    const alert = await this.alertController.create({
      header: '',
      subHeader: '',
      message: msg,
      buttons: [{
        text: this.translate.instant('CANCEL') ,
        role: 'cancel',
      }]
    });

    await alert.present();
  }

  checkMessage(data) {
    
    if (data.code === 'OK') {
      const { vehicles } = data;
      if (this.isIndependent) {
        this.modalController.dismiss(vehicles);
      } else if (vehicles.length === 1){
        this.router.navigate(['/main/operation', vehicles[0].id]);
      } else {
        this.openVehicleList(vehicles);
      }
    }else{
      this.alertMessage(data.error_message);
    }
  }

  async dismissAlert(): Promise<void> {
    return await this.alertController.dismiss().then(() => console.log('dismissed')).catch(() => {});
  }

  async openVehicleList(vehicles) {
    const modal = await this.modalController.create({
      component: SelectVehiclePage,
      componentProps: {
        title: '',
        vehiclesList: vehicles
      }
    });

    await modal.present();
    this.currentModal = modal;
  }
}

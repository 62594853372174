import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class SparePartsService {

  constructor(private apollo: Apollo) { }

  getVehiclesInLavoration() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          vehiclesInLavoration {
            vin
            model
            vehicleBrandName
            takenCrystals {
                id
                partNumber
                description
            }
            takenSpareParts {
                id
                partNumber
                description
            }
          }
        }
      `
    }).valueChanges;
  }

  takeSpareParts(qrcodeString: string) {
    return this.apollo.mutate({
      fetchPolicy: 'no-cache',
      variables: {
        qrcodeString,
      },
      mutation: gql`
        mutation takeSpareParts($qrcodeString: String!) {
          takeSpareParts(input: {qrcodeString: $qrcodeString}) {
            vehicle {
              id
            }
            success
            errors
            message
          }
        }
      `,
    });
  }
}

import { UsersService } from './users.service';
import { VinService } from './vin.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Authentication as Auth } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  link = environment.restUrl + '/send_reset_password_email';

  constructor(
    private apollo: Apollo,
    private platform: Platform,
    private http: HttpClient,
    private userService: UsersService,
    private vinService: VinService
  ) {
    this.platform.ready().then(() => {
      this.isLoggedIn();
    });
   }

  signIn(username: string, password: string) {
    return this.apollo.mutate<Auth>({
      fetchPolicy: 'no-cache',
      variables: { meta: null, email: username, password: password },
      mutation: gql`
        mutation signIn($email: String!, $password: String!){
          signIn( input: { email: $email, password: $password })
          {
            user
            {
              id
              fullName
              firstName
              lastName
              email
              thumbnailPath
              language
              operations {
                id
                description
                title
                operativaChecklistItems{
                  id
                  name
                }
              }
              operativas {
                id
                description
                state
                title
                processingLines
                random
                qrcodeString
                userAssignedRandomVehicle{
                  id
                  vinOrLicensePlate
                  processingRow
                }
              }
              authenticationToken
            }
            success
            errors
          }
        }
        `,
    });
  }

  isLoggedIn() {
    return (
      sessionStorage.getItem('token') && sessionStorage.getItem('token') !== ''
    ) && (
      sessionStorage.getItem('user') && sessionStorage.getItem('user') !== ''
    );
  }

  signOut() {
    sessionStorage.removeItem('token');
    this.userService.destroy();
    this.vinService.destroy();
  }

  reset(data: string) {
    const email = {
      email: data
    };
    return this.http.post(this.link, email);
  }
}

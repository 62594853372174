import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  idUser: number;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private readonly alertService: AlertService,
    private readonly swUpdate: SwUpdate
  ) {
    this.translate.addLangs(['en', 'it', 'fr', 'es']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (this.authenticationService.isLoggedIn()) {
        this.router.navigate(['main']);
      } else {
        this.router.navigate(['login']);
      }

      this.checkForUpdate();
    });
  }

  private checkForUpdate() {
    this.swUpdate.available.subscribe(() => {
      const okHandler = () => document.location.reload();
      const message = this.translate.instant('MESSAGES.newVersionAvailable');
      this.alertService.alertMessage({ message }, true, okHandler);
    });
  }
}

import { LoadingController } from '@ionic/angular';
import { OperationOutcome, Operation, UpdateVehicle, Vehicle, VehicleParams } from './../interfaces/interfaces';
import { UsersService } from './users.service';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { OperationStatus } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private apollo: Apollo,
    private userService: UsersService
  ) { }

  get(id: number) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          vehicle(id: ${id}) {
            id
            vin
            description
            model
            vehicleBrandName
            color
            colorCode
            licensePlate
            note
            pdrPrice
            priorityOrder
            operativa {
              id
              title
              description
              random
              processingLines
            }
            operations {
              id
              title
              description
            }
            availableOperations{
              id
              title
              description
              allowedStates
              canBeWorked
              stateMessage
              pdrCompletedBy
              pdrPrices {
                id
                description
                value
              }
              operativaChecklistItems{
                id
                name
                showOnStates
              }
            }
            operationOutcomesListForUser{
              operation{
                id
                title
                description
              }
              state
            }
            paintDetailsPainted{
                id
                code
                description
                posNumber
                reparationType
                reparationTypeDescription
            }
            paintDetailsToPaint{
              id
              code
              description
              posNumber
              reparationType
              reparationTypeDescription
            }
            takenSpareParts{
              id
              partNumber
              description
            }
            takenCrystals {
              id
              partNumber
              description
            }
            crystals {
              id
              partNumber
              description
            }
          }
        }
      `
    });
  }

  checkProcessingRow(id: number, processingRow?: number) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          checkRandomVehicle(vehicleId: ${id}) {
            vin
          }
        }
      `
    });
  }

  updateVin(id: string, ocr: string, processingRow?: number) {
    return this.apollo.mutate<UpdateVehicle>({
      fetchPolicy: 'no-cache',
      variables: {
        id: id,
        recognizedOcrText: ocr,
        processingRow: processingRow
      },
      mutation: gql`
        mutation updateVehicle($id: ID!, $processingRow: Int, $recognizedOcrText: String!){
          updateVehicle(input: {id: $id, processingRow: $processingRow, recognizedOcrText: $recognizedOcrText}) {
            vehicle {
              id
            }
            errors
          }
        }
      `,
    });
  }

  inOperativa(operativaId: string): boolean {
    return this.userService.getOperativas().filter(operativa => operativa.id === operativaId).length > 0;
  }

  getOperations(operationOutcomes: OperationOutcome[], availableOperations: Operation[]) {
    const operations: Operation[] = [];

    for (const operation of availableOperations) {
      operation.status = JSON.parse(operation.allowedStates)[0] === OperationStatus.closed ? OperationStatus.in_progress : null;
      for (const operationOutcome of operationOutcomes) {
        if (operation.id === operationOutcome.operation.id) {
          operation.status = operationOutcome.state;
        }
      }
      operations.push(operation);
    }

    return operations;
  }

  getInfo(vehicle: Vehicle) {
    return {
      id: vehicle.id,
      vin: vehicle.vin,
      description: vehicle.description,
      model: vehicle.model,
      vehicleBrandName: vehicle.vehicleBrandName,
      color: vehicle.color,
      colorCode: vehicle.colorCode,
      licensePlate: vehicle.licensePlate,
      note: vehicle.note,
      pdrPrice: vehicle.pdrPrice,
      paintDetailsToPaint: vehicle.paintDetailsToPaint,
      paintDetailsPainted: vehicle.paintDetailsPainted,
      priorityOrder: vehicle.priorityOrder,
      takenSpareParts: vehicle.takenSpareParts,
      takenCrystals: vehicle.takenCrystals,
      crystals: vehicle.crystals
    };
  }

  completeCarTransporterLoading(carTransporterId: number, vehicleIds: number[]) {
    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      variables: {
        carTransporterId,
        vehicleIds
      },
      mutation: gql`
        mutation completeCarTransporterLoading($carTransporterId: Int!, $vehicleIds: [Int!]!) {
          completeCarTransporterLoading(input: {carTransporterId: $carTransporterId, vehicleIds: $vehicleIds}) {
            success
            errors
          }
      }
      `,
    });
  }

  completeCarTransporterUnloading(carTransporterId: number, vehicleIds: number[]) {
    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      variables: {
        carTransporterId,
        vehicleIds
      },
      mutation: gql`
        mutation completeCarTransporterUnloading($carTransporterId: Int!, $vehicleIds: [Int!]!) {
          completeCarTransporterUnloading(input: {carTransporterId: $carTransporterId, vehicleIds: $vehicleIds}) {
            success
            errors
          }
      }
      `,
    });
  }

  getExistVIN(vinOrLicensePlate: string) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          vehicle(vinOrLicensePlate:"${vinOrLicensePlate}") {
            vin
            operativa {
              title
            }
        }
      }
      `
    }).valueChanges;
  }

  getOperativasWithCreateVehicle() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          operativasWithCreateVehicles {
            id
            title
           }
        }
      `
    }).valueChanges;
  }

  getVehicleBrands() {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          vehicleBrands {
              id
              name
          }
        }`
      }).valueChanges;
  }

  createVehicle(vehicleTypeParams: VehicleParams) {
    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      variables: {
        vehicleTypeParams
      },
      mutation: gql`
        mutation createVehicle($vehicleTypeParams: VehicleParamsType!){
          createVehicle(input: {vehicleTypeParams: $vehicleTypeParams}) {
            vehicle {
              id
              vin
              licensePlate
              operativaId
            }
            errors
          }
        }
      `
    });
  }

  getGeneric(vinOrLicensePlate: string) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'no-cache',
      query: gql`
        query {
          vehicle(vinOrLicensePlate:"${vinOrLicensePlate}") {
            id
            vin
            description
            model
            vehicleBrandName
            color
            colorCode
            licensePlate
            note
            pdrPrice
            priorityOrder
            vinOrLicensePlate
            operativa {
              id
              title
              description
              random
              processingLines
            }
            operations {
              id
              title
              description
            }
            genericOperations {
              id
              title
              description
              operativaChecklistItems{
                id
                name
                showOnStates
                additionalKoCurrentText {
                  load
                  unload
                }
              }
            }
          }
        }
      `
    });
  }
}

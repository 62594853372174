import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertOptions, AlertButton } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  closeModalOnOk = () => this.closeModal();

  constructor(
    private readonly alertController: AlertController,
    private readonly translate: TranslateService,
    private readonly modalController: ModalController
  ) { }

  async alertMessage(alertOptions: AlertOptions, closeModalOnOk: boolean = false, okHandler = this.closeModalOnOk) {
    const alert = await this.alertController.create({
      message: alertOptions.message || '',
      header: alertOptions.header || this.translate.instant('ATTENTION'),
      subHeader: alertOptions.subHeader || '',
      buttons: this.getButtons(closeModalOnOk, okHandler)
    });

    await alert.present();
  }

  private getButtons(closeModalOnOk: boolean, okHandler: any): AlertButton[] {
    let buttons: AlertButton[] = [
      {
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
      }
    ];
    if (closeModalOnOk) {
      const okButton = {
        text: 'Ok',
        handler: okHandler
      };
      buttons.push(okButton);
    }
    return buttons;
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
